/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  $('#navbar').on('show.bs.collapse', function () {
    $(".main-nav").addClass("aff-menu-active");
    $('.navbar-brand img').attr('src','/wp-content/themes/aff/dist/images/logo-aff-white.svg');

    $('.navbar-toggler i').removeClass("fa-bars");
    $('.navbar-toggler i').addClass("fa-times");
  });
  $('#navbar').on('hidden.bs.collapse', function () {
    $(".main-nav").removeClass("aff-menu-active");
    $('.navbar-brand img').attr('src','/wp-content/themes/aff/dist/images/logo-aff.svg');

    $('.navbar-toggler i').removeClass("fa-times");
    $('.navbar-toggler i').addClass("fa-bars");
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Smooth scrolling
  $('a[href^="#"]').not('#header-search-link').on('click', function(event) {
      var target = $(this.getAttribute('href'));
      if( target.length ) {
          event.preventDefault();
          $('html, body').stop().animate({
              scrollTop: target.offset().top
          }, 1000);
      }
  });

  // Home page help section - video
  $('#homepage_help_modal').on('shown.bs.modal', function (e) {
    // start youtube video when open the modal window
    $('.embed-container iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  });
  $('#homepage_help_modal').on('hide.bs.modal', function (e) {
    // stop youtube video when close the modal window
    $('.embed-container iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  });

  // Icon on submit button for Constant Contact form
  // $("#ctct-submitted").after('<i class="fa fa-long-arrow-right"></i>');

  // Accordion
  $(".accordion .item h4").click(function() {
    $(this).parent('.item').toggleClass('open');
  });

  // Header search
  $("#header-search-link").click(function() {
    $("#header-search-form").fadeIn();
    $("#header-search-form #search").focus();
  });

  // Mobile search
  $("#mobile-search-link").click(function() {
    $("#mobile-search-form").toggle();
    $("#mobile-search-form:visible").find("#search").focus();
  });

  /* Hide search if the container is clicked outside of. */
  $(document).mouseup(function(e) {
    var container = $('#header-search-form');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.hide();
    }
  });

  /* Hide the search bar if the escape key is pressed. */
  $(document).on('keydown', function(e) {
    if (event.key == "Escape") {
      var container = $('#header-search-form');
      container.hide();
    }
  });

})(jQuery); // Fully reference jQuery after this point.
